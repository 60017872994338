<i18n src="@/locales/immediate.json" />

<template>
  <div class="immediate">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <ImanH2>
      {{ $t('h2_title_1') }}
    </ImanH2>
    <section class="section">
      <ImanArticle>
        <p>
          <span v-html="$t('article_p1')" />
        </p>
      </ImanArticle>
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_1') }}
        </h3>
        <p>
          {{ $t('article_p2') }}
        </p>
      </ImanArticle>
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_2') }}
        </h3>
        <p>
          {{ $t('article_p3_1') }}
        </p>
        <p>
          <span v-html="$t('article_p3_2')" />
        </p>
        <p>
          {{ $t('article_p3_3') }}
        </p>
      </ImanArticle>
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_3') }}
        </h3>
        <p>
          {{ $t('article_p4') }}
        </p>
      </ImanArticle>
    </section>
  </div>
</template>

<script>
  import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
  import {metaMixin} from "@/mixins/meta-mixin";

  export default {
    name: 'Immediate',
    mixins: [
      articlesCardContentFilterMixin,
      metaMixin
    ],
    computed: {
      openGraphImgPath () {
        return 'https://anveli.dental' + require('../../../../assets/img/thumbnail/MINIATURE_2.2.4.2.png')
      }
    },
  }
</script>

<style lang="scss" scoped>
  .header-background {
    @media only screen and (min-width: 768px) {
      /* tablet et ordinateurs */
      background-image: url("../../../../assets/img/dental-implant/basal-implant/comparative/immediate/H1-implant-dentaire-immediat-d.jpg");
    }
    @media only screen and (max-width: 767px) {
      /* smartphone en mode paysage */
      background-image: url("../../../../assets/img/dental-implant/basal-implant/comparative/immediate/H1-implant-dentaire-immediat-t.jpg");
    }
    @media only screen and (max-width: 767px) and (orientation: portrait) {
       /* smartphone en mode portait */
      background-image: url("../../../../assets/img/dental-implant/basal-implant/comparative/immediate/H1-implant-dentaire-immediat-m.jpg");
    }
  }
</style>
