import { render, staticRenderFns } from "./Immediate.vue?vue&type=template&id=76bd14f6&scoped=true"
import script from "./Immediate.vue?vue&type=script&lang=js"
export * from "./Immediate.vue?vue&type=script&lang=js"
import style0 from "./Immediate.vue?vue&type=style&index=0&id=76bd14f6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76bd14f6",
  null
  
)

/* custom blocks */
import block0 from "@/locales/immediate.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Fdental-implant%2Fbasal-dental-implant%2Fcomparative%2FImmediate.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports